import { render, staticRenderFns } from "./JoinClub.vue?vue&type=template&id=8e1a1be2"
import script from "./JoinClub.vue?vue&type=script&lang=js"
export * from "./JoinClub.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CModalOverlay: require('@chakra-ui/vue').CModalOverlay, CText: require('@chakra-ui/vue').CText, CModalHeader: require('@chakra-ui/vue').CModalHeader, CModalCloseButton: require('@chakra-ui/vue').CModalCloseButton, CFormLabel: require('@chakra-ui/vue').CFormLabel, CTextarea: require('@chakra-ui/vue').CTextarea, CFormHelperText: require('@chakra-ui/vue').CFormHelperText, CFormControl: require('@chakra-ui/vue').CFormControl, CModalBody: require('@chakra-ui/vue').CModalBody, CButton: require('@chakra-ui/vue').CButton, CModalFooter: require('@chakra-ui/vue').CModalFooter, CModalContent: require('@chakra-ui/vue').CModalContent, CModal: require('@chakra-ui/vue').CModal, CBox: require('@chakra-ui/vue').CBox})
