<template>
  <CBox>
    <CModal :isOpen="isModalOpen" :on-close="close">
      <CModalOverlay />
      <CModalContent
        mx="2"
        mt="-40px"
        style="display: flex; align-items: center; justify-content: center"
      >
        <CModalHeader pb="0"
          >Join Club
          <CText fontSize="sm" fontWeight="500" d="flex">{{
            title ? "In " + title : ""
          }}</CText></CModalHeader
        >
        <CModalHeader>
          <CText fontSize="md" fontWeight="600" d="flex"> Rule: </CText>
          <CText
            fontSize="sm"
            fontWeight="500"
            d="flex"
            white-space="pre-line"
            v-html="formattedDescription(rule)"
            max-h="140px"
            overflow="auto"
            style="word-wrap: break-word"
          ></CText
        ></CModalHeader>
        <CModalCloseButton />
        <CModalBody>
          <CFormControl id="joinReason">
            <CFormLabel for="reason"
              >Please provide a brief reason for joining:</CFormLabel
            >
            <CTextarea id="reason" v-model="params.data.reason" />
            <CFormHelperText
              :color="params.data.reason.length > 250 ? 'red' : 'gray'"
            >
              Character count: {{ params.data.reason.length }}/250
            </CFormHelperText>
            <CFormHelperText v-if="messageFailed" color="red">{{
              messageFailed
            }}</CFormHelperText>
          </CFormControl>
        </CModalBody>
        <CModalFooter>
          <CButton
            colorScheme="blue"
            @click="postData"
            bg="bgButtonClub"
            color="white"
            :_hover="{ opacity: 0.8 }"
            :disabled="!params.data.reason || params.data.reason.length > 250"
          >
            {{ !is_need_approval ? "Send Message" : "Send Request" }}
          </CButton>
        </CModalFooter>
      </CModalContent>
    </CModal>
  </CBox>
</template>

<script>
import {
  CBox,
  CButton,
  CModalOverlay,
  CModalContent,
  CModalHeader,
  CModalCloseButton,
  CModalBody,
  CModalFooter,
  CFormControl,
  CFormLabel,
  CTextarea,
} from "@chakra-ui/vue";
import Swal from "sweetalert2";
import { POST_CLUB_REQUEST } from "@/store/club.module";

export default {
  name: "JoinClub",
  components: {
    CBox,
    CButton,
    CModalOverlay,
    CModalContent,
    CModalHeader,
    CModalCloseButton,
    CModalBody,
    CModalFooter,
    CFormControl,
    CFormLabel,
    CTextarea,
  },
  props: {
    slug: String,
    title: String,
    rule: String,
    is_need_approval: Boolean,
    isModalOpen: Boolean,
  },

  data() {
    return {
      params: {
        slug: this.slug,
        data: {
          reason: "",
        },
      },
      messageFailed: "",
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
  },
  methods: {
    close() {
      this.$emit("closeModal");
    },
    async postData() {
      try {
        if (this.currentUser) {
          this.params.slug = this.slug;
          await this.$store.dispatch(POST_CLUB_REQUEST, this.params);
          this.params.data.reason = "";
          this.$emit("closeModal", true);
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: "success",
            title: "Join request successfully",
          });
        }
      } catch (err) {
        console.log(err.response);
        this.messageFailed = err.response.data.message;
      }
    },
    formattedDescription(value) {
      const regex = /((?:https?|ftp):\/\/\S+)/g;
      const captionWithLinks = value.replace(
        regex,
        '<a href="$1" target="_blank" style="color:blue;">$1</a>'
      );
      return captionWithLinks;
    },
  },
};
</script>
